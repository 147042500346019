.editable-cell {
    position: relative;
}

.editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
}

.datePicker {
    font-size: 16px;
    border: none;
    outline: none;
    background-color: transparent;
    height: 30px;
    width: 50%;
    padding-right: 40px;
    box-shadow: none;
}

.datePicker::placeholder {
    color: rgb(0, 0, 0, 0.32);
}

.ant-picker.ant-picker-disabled{
    border: none;
    box-shadow: none;
    outline: none;
    background-color: transparent;
}

.ant-picker-input > input {
    font-family: HelveticaNeue, sans-serif;
    font-size: 16px;
}
